export const experiments = {
  rafShareOptions: {
    flag: 'raf-share-options'
  },
  depositWelcomeOffer: {
    flag: 'deposit-welcome-offer'
  },
  redirectReturningPlayers: {
    flag: 'redirect-returning-players'
  },
  hideErrorMessageBeforeBlur: {
    flag: 'hide-error-message-before-blur'
  }
};
