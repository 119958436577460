import React, { memo } from 'react';
import { Launcher } from './src/components/Launcher';
import Gnatta, { openLiveChat as openGnattaChat } from './src/components/Gnatta';
import Zendesk from './src/components/Zendesk';

const openLiveChat = () => {
  openGnattaChat();
};

export { openLiveChat };

const LiveChat = () => (
  <>
    {__ENV__.GNATTA_API_KEY ? <Gnatta /> : <Zendesk />}
    <Launcher openLiveChat={openLiveChat} />
  </>
);

export default memo(LiveChat);
