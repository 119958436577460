import React from 'react';
import SideMenu from 'components/SideMenu';
import SearchOverlay from 'components/SearchOverlay';
import UserMenu from 'components/UserMenu';
import Deposit from 'components/Deposit';
import ChangeCard from 'components/ChangeCard';
import AddPayment from 'components/AddPayment';
import YotiIframe from 'components/YotiIframe';
import HooyuIframe from 'components/HooyuIframe';
import KYC from 'components/KYC';
import Withdraw from 'components/Withdraw';
import DepositLimit from 'components/DepositLimit';
import ReferAFriendModal from 'components/ReferAFriendModal';
import DepositLimitSolicitationsModal from 'components/DepositLimitSolicitationsModal';
import CoolOff from 'components/CoolOff';
import SelfExclusion from 'components/SelfExclusion';
import CloseAccount from 'components/CloseAccount';
import RealityCheck from 'components/RealityCheck';
import HowToPlay from 'components/HowToPlay';
import ServicePolicies from 'components/ServicePolicies';
import TermsConditions from 'components/TermsConditions';
import ResponsiblePolicy from 'components/ResponsiblePolicy';
import PrivacyPolicy from 'components/PrivacyPolicy';
import DepositEcospendFlow from 'components/DepositEcospendFlow';
import RewardOfferDetails from 'components/RewardOfferDetails';
import RewardTCModal from 'components/RewardCard/RewardTCModal';
import PciProxy3DChallenge from 'components/PciProxy/3DChallenge/ChallengeModal';
import KeepAliveSlotError from './KeepAliveSlotError';
import AddProfilePhoneModal from './AddProfilePhoneModal';
import AddPaymentPhoneModal from './AddPaymentPhoneModal';
import AddPaypalModal from './AddPaypalModal';
import VerifyMobileModal from './VerifyMobileModal';
import VerifyMobileSuccess from './VerifyMobileSuccess';
import VerifyDepositMobileModal from './VerifyDepositMobileModal';
import ReversalSuccess from './ReversalSuccess';
import SelectReversibleWithdrawals from './SelectReversibleWithdrawals';
import VerifyYourAccount from './VerifyYourAccount';
import VerifyDocuments from './VerifyDocuments';
import LatestTerms from './LatestTerms';

const GlobalModals = () => (
  <div className="modalContainer">
    <SideMenu />
    <SearchOverlay />
    <UserMenu />
    <Deposit />
    <PciProxy3DChallenge />
    <ReversalSuccess />
    <SelectReversibleWithdrawals />
    <ChangeCard />
    <DepositLimit />
    <DepositLimitSolicitationsModal />
    <SelfExclusion />
    {__ENV__.MRQ_ACCOUNT_CLOSURE_ENABLED === 'true' && <CloseAccount />}
    <CoolOff />
    <AddPayment />
    <DepositEcospendFlow />
    <RewardOfferDetails />
    <RewardTCModal />
    <YotiIframe />
    <HooyuIframe />
    <KYC />
    <KeepAliveSlotError />
    <AddProfilePhoneModal />
    <AddPaymentPhoneModal />
    <AddPaypalModal />
    <VerifyYourAccount />
    <VerifyDocuments />
    {__ENV__.MRQ_BINGO_ENABLED === 'true' && <HowToPlay />}
    <Withdraw />
    <VerifyMobileModal />
    <VerifyMobileSuccess />
    <VerifyDepositMobileModal />
    <ReferAFriendModal />
    <ServicePolicies />
    <LatestTerms />
    <TermsConditions />
    <ResponsiblePolicy />
    <PrivacyPolicy />
    <RealityCheck />
  </div>
);

export default GlobalModals;
