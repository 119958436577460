import type { VerticalStepsItem } from 'components/VerticalSteps/types';

export const stepsData: VerticalStepsItem[] = [
  {
    id: 1,
    icon: '/assets/images1/clock.svg',
    title: 'How long does it take?',
    description: `Bank verification is usually instant but may take up to 2 hours. During this time, withdrawals won't be possible. If delayed, try again with the same or a different bank.`
  },
  {
    id: 2,
    icon: '/assets/images1/chat.svg',
    title: 'Get help from our support team',
    description: `If you&apos;re still unable to withdraw after 2 hours, please check out our
          <a href="https://help.mrq.com/hc/en-gb/articles/31452696182420-I-m-seeing-Your-desposit-card-doesn-t-support-withdrawals" target="_blank" className="faq-link">
            FAQ
          </a> 
          for potential solutions or reach out to our support team for further assistance.`
  }
];
