export const NAME = 'Auth';

export const ACCESS_DENIED_PROMPT = {
  LOGIN: 'LOGIN',
  FAILED: 'FAILED',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT'
} as const;

export const MULTI_FACTOR_METHODS = {
  SMS: 'SMS',
  EMAIL: 'EMAIL'
} as const;
