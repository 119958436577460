import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classcat';
// eslint-disable-next-line max-len -- long package name ¯\_(ツ)_/¯
import * as constants from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import amplitude from 'lib/analytics';
import RouterLink from 'components/RouterLink';
import withLazyStyle from 'components/LazyStyle';
import buttonStyle from './button.css?lazy';

/**
 * @typedef {import("./types").ButtonClickedAnalytics} ButtonClickedAnalytics
 */

/**
 * @param {ButtonClickedAnalytics} properties
 * @returns {import("@amplitude/analytics-types").AmplitudeReturn<import("@amplitude/analytics-types").Result>}
 */
const trackButtonClicked = (properties) => amplitude.track('Button Clicked', properties);

/**
 * Extract properties and prepare them for the "Button Clicked" event. Falsy values turn to undefined so that they're
 * excluded from the event payload.
 *
 * @param e {import('react').MouseEvent}
 * @param properties
 * @param [properties.icon] {string}
 * @param [properties.actionRefs] {string[]}
 * @returns {ButtonClickedAnalytics}
 */
export const extractProperties = ({ currentTarget }, { icon, actionRefs, name }) => ({
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_NAME]: name,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ID]: currentTarget.id || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_CLASS]: currentTarget.className || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TEXT]: currentTarget.textContent?.trim?.() || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_ARIA_LABEL]:
    currentTarget.getAttribute('aria-label') || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_LABEL]:
    currentTarget.labels?.[0]?.textContent?.trim?.() || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TITLE]: currentTarget.title || undefined,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_VALUE]: currentTarget.value || undefined,
  'Button Has Icon': !!icon,
  'Action Refs': actionRefs,
  [constants.AMPLITUDE_EVENT_PROP_ELEMENT_TAG]: currentTarget.tagName.toLowerCase(),
  [constants.AMPLITUDE_EVENT_PROP_FORM_NAME]: currentTarget.form?.name || undefined,
  [constants.AMPLITUDE_EVENT_PROP_FORM_ID]: currentTarget.form?.id || undefined
});

/**
 * The button building block.
 */
class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expired: false
    };
  }

  handleOneShot = (e) => {
    this.props.onClick(e);
    e.currentTarget.blur();
    this.setState({ expired: true });
    this.forceUpdate();
  };

  handleClick = (e) => {
    const { oneShot, onClick, icon, actionRefs, name } = this.props;
    const { expired } = this.state;
    if (!expired) {
      if (name) {
        trackButtonClicked(extractProperties(e, { icon, actionRefs, name }));
      }
      if (oneShot && onClick) {
        this.handleOneShot(e);
      } else {
        onClick?.(e);
      }
    }
  };

  render() {
    const {
      tag,
      className,
      id,
      type,
      disabled,
      buttonText,
      href,
      title,
      children,
      value,
      onClick,
      loading,
      icon,
      horizontal,
      to,
      name,
      actionRefs,
      unstyled,
      oneShot,
      fakeDisabled, // Allows pointer events
      ...rest
    } = this.props;
    const { expired } = this.state;

    const buttonClass = classNames([
      'amp-block-track',
      className,
      {
        button: !unstyled,
        'button--iconButton': icon && !buttonText && !children,
        'button--combo': icon && buttonText && !loading,
        'button--expired': expired,
        'button--disabled': fakeDisabled,
        'combo--horizontal': icon && buttonText && horizontal,
        'button--loading': loading
      }
    ]);

    // TODO: This sucks, change it
    if (tag === 'a' || tag === 'Link') {
      return (
        <RouterLink
          className={buttonClass}
          id={id}
          href={to || href}
          title={title}
          onClick={this.handleClick}
          value={value}
          name={name}
          actionRefs={actionRefs}
          {...rest}
          disabled={disabled || !!loading}
        >
          {!loading ? children : null}
          {!loading && icon ? <img alt={title} className="buttonIcon" src={icon} /> : null}
          {loading ? (
            <img
              alt="Loading"
              className="buttonIcon buttonIcon--loading"
              src="/assets/images1/loader-ring.svg"
              data-chromatic="ignore"
            />
          ) : (
            buttonText && <span className="buttonText">{buttonText}</span>
          )}
        </RouterLink>
      );
    } else if (tag === 'div') {
      // TODO: Give it role="button"
      return (
        <div className={buttonClass} title={title} onClick={this.handleClick} id={id} {...rest}>
          {!loading ? children : null}
          {!loading && icon ? <img alt={title} className="buttonIcon" src={icon} /> : null}
          {loading ? (
            <img
              alt="Loading"
              className="buttonIcon buttonIcon--loading"
              src="/assets/images1/loader-ring.svg"
              data-chromatic="ignore"
            />
          ) : (
            buttonText && <span className="buttonText">{buttonText}</span>
          )}
        </div>
      );
    } else {
      return (
        <button
          className={buttonClass}
          // eslint-disable-next-line react/button-has-type -- https://github.com/yannickcr/eslint-plugin-react/issues/1555
          type={type}
          title={title}
          disabled={disabled || !!loading}
          onClick={this.handleClick}
          value={value}
          id={id}
          name={name}
          {...rest}
        >
          {!loading ? children : null}
          {!loading && icon ? <img alt={title} className="buttonIcon" src={icon} /> : null}
          {loading ? (
            <img
              alt="Loading"
              className="buttonIcon buttonIcon--loading"
              src="/assets/images1/loader-ring.svg"
              data-chromatic="ignore"
            />
          ) : (
            buttonText && <span className="buttonText">{buttonText}</span>
          )}
        </button>
      );
    }
  }
}

Button.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  actionRefs: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  horizontal: PropTypes.bool,
  loading: PropTypes.bool,
  buttonText: PropTypes.node, // can accept an Icon too for example
  href: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  /** Only clickable once */
  oneShot: PropTypes.bool,
  unstyled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  fakeDisabled: PropTypes.bool
};

Button.defaultProps = {
  tag: 'button',
  className: null,
  icon: null,
  name: null,
  actionRefs: undefined,
  value: '',
  id: null,
  type: 'button',
  disabled: false,
  horizontal: false,
  loading: false,
  buttonText: null,
  href: null,
  to: null,
  title: null,
  oneShot: false,
  unstyled: false,
  children: null,
  onClick: null,
  fakeDisabled: false
};

export default withLazyStyle(buttonStyle)(Button);
