import { country } from 'lib/messages';

export const initialState = {
  avatar: 'greenguy',
  theme: '#00C3BE',
  chatDisabled: true,
  gameSoundsEnabled: true,
  chatSoundsEnabled: true,
  gameSoundsVoice: 'female',
  enableStickyChat: true,
  enableStatefulChat: true,
  fullName: '',
  email: '',
  phoneNumber: '',
  phoneVerified: false,
  password: '',
  ref: null,
  fbUserId: null,
  accessToken: null,
  username: '',
  firstName: '',
  lastName: '',
  birthDate: null,
  sex: 'Undisclosed',
  postcode: '',
  address: '',
  city: '',
  country: country,
  regPromoCode: null,
  referal: null,
  pba: null,
  receiveEmail: true,
  receiveSms: true,
  receivePost: true,
  receiveCalls: true,
  enabled: true,
  activated: null,
  showBalance: true,
  referees: null,
  freeSpinsEarned: null,
  freeSpinsPending: null,
  freeRounds: 0,
  realityCheckInterval: null,
  sessionWinnings: 0,
  sessionLosses: 0,
  sessionDurationInSeconds: 0,
  firstDepositor: null,
  freeRoundsGames: [],
  rewards: null,
  dailyFreeGames: {
    entities: null,
    result: null
  },
  netDeposits: {},
  netBets: {},
  rewardsCount: null,
  rewardOffers: null,
  realityTimeoutId: null,
  realityIntervalId: null,
  deleted: false,
  avatarNotification: null,
  returningPlayer: null,
  feedback: null,
  kycStatus: null,
  kycProviders: null,
  affiliate: null,
  agreedToLatestTerms: true,
  liveChatLoaded: false,
  isLauncherVisible: false,
  requiresVerification: false,
  verification: {
    journey: null,
    status: null,
    provider: null
  },
  kycVerificationDocuments: [],
  gnattaProfile: null,
  hooyuLink: null
};

// Stable references
const empty = [];
const chat = ['chat'];

export const username = (model) => model?.username;
export const affiliate = (model) => model?.affiliate;
export const registrationPromoCode = (model) => model?.regPromoCode;
export const freeRounds = (model) => model?.freeRounds;
export const freeRoundsGames = (model) => model?.freeRoundsGames;
export const referees = (model) =>
  (model && model.referees && model.referees.entities) || undefined;
export const refereeRefs = (model) => (model && model.referees && model.referees.result) || [];
export const rewards = (model) => (model && model.rewards) || [];
export const dailyFreeGames = (model) => model?.dailyFreeGames?.entities || {};
export const dailyFreeGameRefs = (model) => model?.dailyFreeGames?.result || [];
export const dailyFreeGamesLoaded = (model) => !!model?.dailyFreeGames?.result;
export const netDeposits = (model) => model?.netDeposits;
export const netBets = (model) => model?.netBets;
export const slotRewardsLoaded = (model) => !!model?.rewards;
export const rewardOffers = (model) => (model && model.rewardOffers) || undefined;
export const rewardsCount = (model) => model && model.rewardsCount;
export const freeSpinsEarned = (model) => (model && model.freeSpinsEarned) || undefined;
export const freeSpinsPending = (model) => (model && model.freeSpinsPending) || undefined;
export const homeAddress = (model) => model.homeAddress ?? undefined;
export const disabledTabs = (model) => (model && model.chatDisabled ? chat : empty);
export const requiresVerification = (model) => model?.requiresVerification;
export const verificationStatus = (model) => model?.verification?.status;
export const verificationProvider = (model) => model?.verification?.provider;
export const verificationType = (model) => model?.verification?.type;
export const liveChatUnreadCount = (model) => model?.liveChatUnreadCount;
export const liveChatLoaded = (model) => model?.liveChatLoaded;
export const isLauncherVisible = (model) => model?.isLauncherVisible;
export const avatar = (model) => model?.avatar;
export const returningPlayer = (model) => model?.returningPlayer;
export const realityCheckInterval = (model) => model?.realityCheckInterval;
export const kycVerificationDocuments = (model) => model?.kycVerificationDocuments;
export const pbaFlag = (model) => model?.pba;
export const email = (model) => model?.email;
export const gnattaProfile = (model) => model?.gnattaProfile;
