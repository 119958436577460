import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import cc from 'classcat';
import { nanoid } from 'nanoid';
// eslint-disable-next-line max-len -- long package name ¯\_(ツ)_/¯
import * as constants from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import { useAppDispatch } from 'hooks/redux';
import UI from 'modules/UI';
import Modals from 'modules/Modals';
import amplitude from 'lib/analytics';
import { CopyIcon, CheckmarkIcon } from 'components/Icons';
import './copyToClipboard.css';

const SuffixDefault = () => (
  <CopyIcon
    className="copyToClipboard__suffixDefault"
    width="1.5em"
    height="1.5em"
    viewBox="0 0 24 24"
  />
);

const SuffixCopiedDefault = () => (
  <CheckmarkIcon
    className="copyToClipboard__suffixDefault"
    width="1.5em"
    height="1.5em"
    viewBox="0 0 24 24"
  />
);

interface CopyToClipboardAdapterProps {
  children: ReactNode;
  name?: string;
  text: string;
  className?: string;
  notification?: string;
  suffix?: JSX.Element;
  suffixCopied?: JSX.Element;
}

const CopyToClipboard = ({
  children,
  name,
  text,
  className,
  notification = 'Copied!',
  suffix = <SuffixDefault />,
  suffixCopied = <SuffixCopiedDefault />
}: CopyToClipboardAdapterProps) => {
  const dispatch = useAppDispatch();
  const [hasCopied, setHasCopied] = useState(false);

  const handleClick = useCallback(() => {
    if (!hasCopied) {
      setHasCopied(true);
      amplitude.track('Text Copied', {
        [constants.AMPLITUDE_EVENT_PROP_ELEMENT_CLASS]: className,
        [constants.AMPLITUDE_EVENT_PROP_ELEMENT_NAME]: name,
        [constants.AMPLITUDE_EVENT_PROP_ELEMENT_VALUE]: text
      });
      dispatch(UI.actions.copy(text));
      dispatch(
        Modals.actions.create(nanoid(), {
          notification: true,
          creationDate: Date.now(),
          message: notification,
          expiration: 3000,
          announcement: false,
          pinned: true,
          blocking: false,
          closeOnNavigation: true,
          severity: 2,
          destroyOnClose: true
        })
      );
    }
  }, [dispatch, hasCopied, name, text, className, notification]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (hasCopied) {
        setHasCopied(false);
      }
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [hasCopied]);

  return (
    <span onClick={handleClick} className={cc(['copyToClipboard', className])}>
      {children}
      <span className="copyToClipboard__suffix">{hasCopied ? suffixCopied : suffix}</span>
    </span>
  );
};

export default CopyToClipboard;
