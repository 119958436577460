import type { VerticalStepsItem } from 'components/VerticalSteps/types';

export const stepsData: VerticalStepsItem[] = [
  {
    id: 1,
    icon: '/assets/images1/bank.svg',
    title: "Just a few quick steps to get your withdrawal on it's way",
    description: `Connect your bank account, deposit 1p and you're ready to withdraw.`
  },
  {
    id: 2,
    icon: '/assets/images1/pound.svg',
    title: "What's next?",
    description: `Once confirmation from your bank is received, usually in seconds, you'll be able to select the amount you want to withdraw.`
  },
  {
    id: 3,
    icon: '/assets/images1/clock.svg',
    title: "You're done!",
    description: `Simple as that, your withdrawal will be with you in a maximum of 48hrs.`
  },
  {
    id: 4,
    icon: '/assets/images1/chat.svg',
    title: 'Need help?',
    description: `Give us a shout if you need some help and you're still experiencing issues connecting your bank after 2hrs.`
  }
];

export const hasPaymentMethodStepsData: VerticalStepsItem[] = [
  {
    id: 1,
    icon: '/assets/images1/bank.svg',
    title: 'Withdraw to bank',
    description: `Your deposit card doesn't support withdrawals, but no worries - you can withdraw to your bank account!`
  },
  {
    id: 2,
    icon: '/assets/images1/clock.svg',
    title: 'How long does it take?',
    description: `Pop in the amount you'd like to withdraw, and we'll get it sorted in no time.`
  },
  {
    id: 3,
    icon: '/assets/images1/chat.svg',
    title: 'Get help from our support team',
    description: `Give us a shout if you need some help or check our
          <a href="https://help.mrq.com/hc/en-gb/articles/31452696182420-I-m-seeing-Your-desposit-card-doesn-t-support-withdrawals" target="_blank" className="faq-link">
            FAQ
          </a>.`
  }
];
