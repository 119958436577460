import { ContactFieldType } from './constants';

export const referralTypeText = {
  [ContactFieldType.EMAIL]: {
    button: 'Invite by Email',
    notification: `We emailed your friends with the offer. Reach out to them if you think they may confuse this email with spam.`
  },
  [ContactFieldType.TEL]: {
    button: 'Invite by Text Message',
    notification: `We texted your friends with the offer. Reach out to them if you think they may confuse this SMS with spam.`
  }
};
