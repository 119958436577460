import React, { useCallback } from 'react';
import type { MouseEvent } from 'react';
import { useAppSelector } from 'hooks/redux';
import User from 'modules/User';
import { Typography, TypographyVariant } from 'components/Typography';
import Heading from 'components/Heading';
import Button from 'components/Button';
import CopyToClipboard from 'components/CopyToClipboard';
import { ContactFieldType } from '../../constants';
import { referralTypeText } from '../../data';
import './initialScreen.css';

interface InitialScreenProps {
  username: string;
  freeRounds: number;
  onInviteTypeButtonClicked: (e: MouseEvent<HTMLElement>) => void;
}

export const InitialScreen = ({
  username,
  freeRounds,
  onInviteTypeButtonClicked
}: InitialScreenProps) => (
  <div className="initialScreen">
    <div className="initialScreen__content">
      <Typography variant={TypographyVariant.HeaderXs}>
        <Heading level={2} className="initialScreen__heading">
          Get and Gift {freeRounds} Free Spins for every friend you invite
        </Heading>
      </Typography>
      <Typography variant={TypographyVariant.BodySm}>
        <div className="initialScreen__description">
          Just copy the unique link below and send it to your friends to use when they sign up. You
          can also let us text or email your friends.
        </div>
      </Typography>
      <CopyToClipboard
        name="referFriend"
        className="initialScreen__inviteCopyButton"
        text={`https://mrq.com/invited/${username}`}
        notification="Link copied. Share it on WhatsApp, Messenger as many people as you want."
        suffix={
          <Typography variant={TypographyVariant.BodySmStrong}>
            <span className="initialScreen__inviteCopyText">Copy</span>
          </Typography>
        }
        suffixCopied={
          <Typography variant={TypographyVariant.BodySmStrong}>
            <span className="initialScreen__inviteCopyText">Copied</span>
          </Typography>
        }
      >
        <Typography variant={TypographyVariant.BodySmStrong}>
          <span className="initialScreen__inviteLinkText">{`mrq.com/invited/${username}`}</span>
        </Typography>
      </CopyToClipboard>
    </div>

    <div className="initialScreen__actionGroup">
      <Button
        value={ContactFieldType.TEL}
        buttonText={referralTypeText[ContactFieldType.TEL].button}
        name="referFriend"
        onClick={onInviteTypeButtonClicked}
        className="button--fullWidth"
      />
      <Button
        value={ContactFieldType.EMAIL}
        buttonText={referralTypeText[ContactFieldType.EMAIL].button}
        name="referFriend"
        onClick={onInviteTypeButtonClicked}
        className="button__grey button--fullWidth"
      />
      <Button
        tag="Link"
        href="/promotions/refer-a-friend#terms-and-conditions"
        target="_blank"
        className="button--fakeLink button--fullWidth"
      >
        <Typography variant={TypographyVariant.BodySmStrong}>
          <span>T&Cs Apply</span>
        </Typography>
      </Button>
    </div>
  </div>
);

interface InitialScreenAdapterProps {
  onInviteTypeButtonClicked: (contactFieldType: ContactFieldType) => void;
}

const InitialScreenAdapter = ({ onInviteTypeButtonClicked }: InitialScreenAdapterProps) => {
  const username = useAppSelector(User.selectors.getUsername);
  const freeRounds = useAppSelector(User.selectors.getFreeRounds);

  const handleInviteTypeButtonClicked = useCallback(
    (e) => {
      e.preventDefault();
      const contactFieldType: ContactFieldType = e.currentTarget && e.currentTarget.value;
      onInviteTypeButtonClicked(contactFieldType);
    },
    [onInviteTypeButtonClicked]
  );

  return (
    <InitialScreen
      username={username}
      freeRounds={freeRounds}
      onInviteTypeButtonClicked={handleInviteTypeButtonClicked}
    />
  );
};

export default InitialScreenAdapter;
